import React from "react";
// react-bootstrap components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// logo for footer
import FooterLogo from "../assets/images/TRG-FleurdeLis- Grey.png";

// footer css
import "./footer.css";

import { useNavigate } from "react-router-dom";

function Footer(props) {
  const navigation = useNavigate();

  return (
    <Container fluid style={{ backgroundColor: "#171717", padding: "20px" }}>
      {/* 5 columns with header and links below  */}
      <Row style={{ padding: "20px" }}>
        <Col>
          <img
            src={FooterLogo}
            alt="logo"
            style={{ width: "5rem", objectFit: "contain" }}
            onClick={() => navigation("/")}
          />
        </Col>
        <Col>
          <h6 style={{ fontWeight: "bold", color: "#ededed" }}>Membership</h6>
          <p className="footer-links">Pitch Deck</p>
        </Col>
        <Col>
          <h6 style={{ fontWeight: "bold", color: "#ededed" }}>Products</h6>
          <p className="footer-links">
            <a
              href="https://apps.apple.com/us/app/cuff-collar/id6448968519?itsct=apps_box_promote_link&itscg=30200"
              className="footer-links"
              target="_blank"
              rel="noreferrer noopener"
            >
              Cuff & Collar
            </a>
          </p>
          <p className="footer-links">Cadbury.ai</p>
          <p className="footer-links">
            <a
              href="https://tententexas.com"
              className="footer-links"
              target="_blank"
              rel="noreferrer noopener"
            >
              X:X Texas
            </a>
          </p>
        </Col>

        <Col>
          <h6 style={{ fontWeight: "bold", color: "#ededed" }}>Social</h6>

          <p className="footer-links">
            <a
              href="https://www.instagram.com/theroyalguardclub/"
              target="_blank"
              rel="noreferrer noopener"
              className="footer-links"
            >
              Instagram
            </a>
          </p>
        </Col>
      </Row>
      <Row className="pt-5 ">
        <Col style={{ display: "flex", justifyContent: "center" }}>
          <a
            style={{
              color: "#9a1e28",
              fontSize: "10px",
              textDecoration: "none",
            }}
            href="https://bboycreative.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            Made with ❤️ by @bboycreative.io{" "}
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
