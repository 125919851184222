import React, { Fragment } from "react";
// styles
import "./app.css";
// components
import Footer from "./components/Footer";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeroLogo from "./assets/images/backgroundOne.png";

function App() {
  return (
    <Fragment>
      {/* Landing page */}
      <Container fluid>
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <Col
            xs={12}
            md={6}
            lg={6}
            xl={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <Image
              src={HeroLogo}
              alt="login"
              style={{
                objectFit: "contain",
                width: "350px",
                height: "250px",
              }}
            />
            <Container
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: "20px" }}
            >
              <p style={{ fontSize: "1rem", lineHeight: "25px" }}>
                The Royal Guard - Style Agency is a premier style agency that
                provides a curated menswear experience. We offer a variety of
                services that include personal shopping, wardrobe overhauls,
                personal styling, and more. Our memberships are by way of
                invitation only or from a member of our staff.
              </p>
            </Container>
          </Col>
        </Row>
      </Container>

      <Footer />
    </Fragment>
  );
}

export default App;
